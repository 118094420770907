.experience-suzano_layouts-categoriesRoundCarousel,
.experience-dynamic-dynamicCategoriesRound{
    padding-top: 24px;
    padding-bottom: 24px;

    @media (max-width: 576px) {
        padding-top: 12px;
        padding-bottom: 12px;
    }
}

.js-categoryRoundCarousel.skeleton-loaderCarousel .categoryRoundCarousel{
    display: grid;
    overflow: hidden;
    column-gap: 12px;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1fr 0 0 0 0 0;
    overflow: hidden;

    @media (max-width: 1280px) {
        grid-template-columns: repeat(10, 1fr);
    }
    @media (max-width: 992px) {
        grid-template-columns: repeat(8, 1fr);
    }
    @media (max-width: 768px) {
        grid-template-columns: repeat(6, 1fr);
    }
    @media (max-width: 576px) {
        grid-template-columns: repeat(6, 1fr);
    }
}

.categoryRoundCarousel{
    margin-left: inherit;
    margin-right: inherit;

    .slick-track {
        .slick-slide{
            margin: 0px 6px;
            @media (max-width: 576px) {
                margin: 0px 3px;
            }
        }
    }
}

.categoryRoundHeading {
    display: flex;

    h2 {
        color: #23346c;
        font-size: 23px;
        font-family: "Open Sans" !important;
        font-weight: 700;
        margin-bottom: 24px;
    }
}

@media (max-width: 991px) {
    .categoryRoundHeading h2 {
        text-align: center;
    }
}

@media (max-width: 576px) {
    .categoryRoundHeading h2 {
        font-size: 1.2rem;
        margin-bottom: 12px;
    }
}

.experience-suzano_assets-categoryBoxItem{
    padding-bottom: 2px;
}

.experience-suzano_layouts-categoriesRoundCarousel,
.experience-dynamic-dynamicCategoriesRound {
    &:has(.noShow) {
        display: none;
    }
}

@media (min-width: 993px) {
    .experience-suzano_layouts-categoriesRoundCarousel,
    .experience-dynamic-dynamicCategoriesRound {
        &:has(.showDesktop) {
            display: block;
        }
    }
}

@media (max-width: 992px) {
    .experience-suzano_layouts-categoriesRoundCarousel,
    .experience-dynamic-dynamicCategoriesRound {
        &:has(.showTablet) {
            display: block;
        }
    }
}

@media (max-width: 576px) {
    .experience-suzano_layouts-categoriesRoundCarousel,
    .experience-dynamic-dynamicCategoriesRound {
        &:has(.showMobile) {
            display: block;
        }
    }
}

